import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/AuthenticationInner/Login2"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/AuthenticationInner/Register2"
import ForgetPwd from "../pages/AuthenticationInner/ForgetPassword2"



// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Providers from "../pages/Providers/index"
import Tests from "../pages/Tests/index"
import Restrictions from "../pages/Restrictions/index"




const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/providers", component: Providers },
  { path: "/tests", component: Tests },
  { path: "/restrictions", component: Restrictions },



  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
