import React from "react"
import { Container, Row } from "reactstrap"
import MetaTags from 'react-meta-tags';

//import component
import CardUser from "./CardUser"
import Settings from "./Settings"
import Posts from "./Posts"
import Comments from "./Comments"
import Notifications from "./notifications"

import TapVisitors from "./TapVisitors"
import Activity from "./Activity"
import PopularPost from "./PopularPost"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const index = props => {
  const series = [
    {
      name: "Tests Booked",
      data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
    },
    {
      name: "Revenue",
      data: [1242,	1449,	3105,	2484,	4485,	3243,	3519	,2208,	2760,	1932,	2139	,1794],
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          }
        },
      
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: 'Revenue',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FEB019'
        },
        labels: {
          style: {
            colors: '#FEB019',
          },
        },
        title: {
          text: "Revenue",
          style: {
            color: '#FEB019',
          }
        }
      },
    ],

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TestandTrip | Simplifying Your Travel Booking</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
          <Row>
            {/* card user */}
            <CardUser options={options} series={series} />
            <Notifications />
          </Row>
          <Row>
          
            <Comments />
           
          </Row>
        
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
