import React from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import MetaTags from 'react-meta-tags';
import { Col, Container, Form, Row, Input } from "reactstrap"
import { withRouter, Link } from "react-router-dom"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import logodark from "../../assets/images/logo-light.png"
import logolight from "../../assets/images/logo-dark.png"
import CarouselPage from "./CarouselPage"

const Login = props => {
  const dispatch = useDispatch()

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>TestandTrip - Automated Cloud Operations</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={6}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-50">
                  <div className="d-flex flex-column h-100">
                    
                    <div className="my-auto">
                    <div className="mb-1 mb-md-1">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="100"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="100"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                      <div>
                      
                        <p className="text-muted">
                          Sign in to continue to TestandTrip.
                        </p>
                      </div>

                      <div className="mt-4">
                        <AvForm className="form-horizontal"
                         onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                        >
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              value="admin@TestandTrip.com"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="auth-recoverpw-2"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div>
                            <AvField
                              name="password"
                              label="Password"
                              value="123456"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              required
                            />
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                        </AvForm>

                      
                        <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account ?
                            <br/>
                            <Link
                              to="register"
                              className="fw-medium text-primary"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} TestandTrip. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
