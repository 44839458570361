import React, { useState } from "react"
import { Link } from "react-router-dom"
import ReactDrawer from 'react-drawer';
import Select from "react-select"

import {
  Card,
  CardBody,
  CardHeader,
  Badge,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle, Button,
  Form,Input,Label
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import classnames from "classnames"
import SimpleBar from "simplebar-react";


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

import Overview from "./../Overview"


const DatatableTables = () => {

  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [selectedGroup, setselectedGroup] = useState(null)

  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleTopDrawer = () => {
    setPosition('top');
    setOpen(!open)
  }
  const toggleBottomDrawer = () => {
    setPosition('bottom');
    setOpen(!open)
  }
  const toggleLeftDrawer = () => {
    setPosition('left');
    setOpen(!open)
  }
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }
  const onDrawerClose = () => {
    setOpen(false);
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "North East",
      options: [
        { label: "Newcastle Test Centre - Unit 1 Killingworth Road Newcastle NE3 1SY", value: "Newcastle Test Centre - Unit 1 Killingworth Road Newcastle NE3 1SY" },
        { label: "Stockton-on-Tees Test Centre - 148 High Street Stockton on Tees TS18 1PL", value: "Stockton-on-Tees Test Centre - 148 High Street Stockton on Tees TS18 1PL" }
      ]
    },
    {
      label: "North West",
      options: [
        { label: "Liverpool Test Centre - 57 Rodney Street Liverpool L1 9AT", value: "Liverpool Test Centre - 57 Rodney Street Liverpool L1 9AT" },

        { label: "Liverpool Rose Lane Test Centre - Rose Lane Mossley Hill Liverpool L18 8ES", value: "Liverpool Rose Lane Test Centre - Rose Lane Mossley Hill Liverpool L18 8ES" },
        { label: "Manchester Test Centre - Unit 3, Empress Business Centre Chester Rd M16 9EA", value: "Manchester Test Centre - Unit 3, Empress Business Centre Chester Rd M16 9EA" }
      ]
    }
  ]


  const columns = [ {
    dataField: 'name',
    text: 'Name',
    sort: true
  },{
    dataField: 'region',
    text: 'Region',
    sort: true
  }, {
    dataField: 'provider',
    text: 'Provider',
    sort: true
  },  {
    dataField: 'test',
    text: 'Test',
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12 badge-soft-dark"}
       
        pill
      >
        {row.test}
      </Badge>
    ),
  }, {
    dataField: 'status',
    text: 'Status',
    sort: true
  }, {
    dataField: 'testdate',
    text: 'Date',
    sort: true
  },  {
    dataField: 'amount',
    text: 'Amount',
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <>
        
       {row.currency} {row.amount}
      </>
    ),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "View Details",
    sort: true,
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        toggleRightDrawer()
      }
    },
    // eslint-disable-next-line react/display-name
    formatter: () => (
      <Button
        type="button"
        color="primary"
        className="btn-sm"
       
      >
        View Details
      </Button>
    )
  },

];

  // Table Data
  const productData = [
    {
      "name": "Jennifer Munoz",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "05-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Meghan Morse",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "06-Nov-21",
      "status": "Completed",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "Elizabeth Rogers",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "07-Nov-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "John Douglas",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "08-Nov-21",
      "status": "Completed",
      "amount": 39,
      "currency": "£"
    },
    {
      "name": "Donna Kennedy",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "09-Nov-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Robin Patterson",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "10-Nov-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Tiffany Meyer",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "11-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Joshua Taylor",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "12-Nov-21",
      "status": "Completed",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "William Hernandez",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 PCR",
      "createddate": "01-Nov-21",
      "testdate": "13-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Richard Ward",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 PCR",
      "createddate": "01-Nov-21",
      "testdate": "14-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Jonathan Reynolds",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "createddate": "01-Nov-21",
      "testdate": "15-Nov-21",
      "status": "Completed",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "Gene Chen",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "createddate": "01-Nov-21",
      "testdate": "16-Nov-21",
      "status": "Booked",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "William Gross",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "17-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Erica Stokes",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "18-Nov-21",
      "status": "Booked",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "Devon Evans",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "19-Nov-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Anthony Hull",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "20-Nov-21",
      "status": "Completed",
      "amount": 39,
      "currency": "£"
    },
    {
      "name": "Michelle Anderson",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "21-Nov-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Misty Acevedo",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "22-Nov-21",
      "status": "Completed",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Aimee Doyle",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "23-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Jill Fischer",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "24-Nov-21",
      "status": "Completed",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "Kimberly Ellison",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 PCR",
      "createddate": "01-Nov-21",
      "testdate": "25-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Julian Morris",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 PCR",
      "createddate": "01-Nov-21",
      "testdate": "26-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Linda Jones",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "createddate": "01-Nov-21",
      "testdate": "27-Nov-21",
      "status": "Booked",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "Adam Patel",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "createddate": "01-Nov-21",
      "testdate": "28-Nov-21",
      "status": "Completed",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "Bobby Russo",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "29-Nov-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Jason Chavez",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "30-Nov-21",
      "status": "Booked",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "Barbara Ryan",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "01-Dec-21",
      "status": "Completed",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Ricardo Douglas",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "02-Dec-21",
      "status": "Booked",
      "amount": 39,
      "currency": "£"
    },
    {
      "name": "David Brown",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "03-Dec-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Haley Williams",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "04-Dec-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Kelly Patrick",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "05-Dec-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Nicole Price",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "06-Dec-21",
      "status": "Completed",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "Diane Underwood",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 PCR",
      "createddate": "01-Nov-21",
      "testdate": "07-Dec-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Todd Smith",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 PCR",
      "createddate": "01-Nov-21",
      "testdate": "08-Dec-21",
      "status": "Completed",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Crystal Reyes",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "createddate": "01-Nov-21",
      "testdate": "09-Dec-21",
      "status": "Booked",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "Kaitlyn Henderson",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "createddate": "01-Nov-21",
      "testdate": "10-Dec-21",
      "status": "Completed",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "Laura Medina",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "11-Dec-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Colleen Brown",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "12-Dec-21",
      "status": "Booked",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "Patricia Miller",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "13-Dec-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Cindy David",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "14-Dec-21",
      "status": "Completed",
      "amount": 39,
      "currency": "£"
    },
    {
      "name": "Lisa Jackson",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "15-Dec-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Lacey Zimmerman",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "16-Dec-21",
      "status": "Booked",
      "amount": 29,
      "currency": "£"
    },
    {
      "name": "Christopher Thomas",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | RT-PCR Test",
      "createddate": "01-Nov-21",
      "testdate": "17-Dec-21",
      "status": "Completed",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Ashley Martin",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "18-Dec-21",
      "status": "Booked",
      "amount": 149,
      "currency": "£"
    },
    {
      "name": "Sherri Bowen",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "19-Dec-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Todd Bell",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "20-Dec-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Christopher Stout",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "createddate": "01-Nov-21",
      "testdate": "21-Dec-21",
      "status": "Booked",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "Nicole Jones",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "22-Dec-21",
      "status": "Completed",
      "amount": 119,
      "currency": "£"
    },
    {
      "name": "John Fox",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Day 5 Test to Release",
      "createddate": "01-Nov-21",
      "testdate": "23-Dec-21",
      "status": "Booked",
      "amount": 69,
      "currency": "£"
    },
    {
      "name": "Karen Watson",
      "region": "United Kingdom",
      "provider": "DAM Health",
      "test": "Non-Vaccinated Day 2 PCR",
      "createddate": "01-Nov-21",
      "testdate": "24-Dec-21",
      "status": "Completed",
      "amount": 149,
      "currency": "£"
    }
  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Booked Tests</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Tests" />



          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
              <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-flex flex-wrap">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive ">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                   
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>

</CardBody>
              </Card>
            </Col>
          </Row>
          <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
          >
            <SimpleBar style={{ height: "900px" }}>
              <div data-simplebar className="h-100">

                <Container className="mt-3">
                <Card outline color="grey" className="border">
               
                <CardBody>
                <h5 className="text-primary">
                  Fit to Fly | RT-PCR Test - £69
                  </h5>
                  <CardTitle className="mt-0">DAM Health</CardTitle>
               
                  <Row>
                  <Col md={6}>
                  <div className="mb-1">
                    <Label htmlFor="formrow-email-Input">Choose the test center </Label>
                      <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                   <Button className="btn-sm mt-2">View on Map</Button>
                    </div>
                    </Col>
                    <Col md={6}>
                    <div className="mb-1">

                    <label
                      htmlFor="example-datetime-local-input"
                    >
                      Choose Date and Time
                    </label>
            
                      <input
                        className="form-control"
                        type="datetime-local"
                        defaultValue="2021-11-01T13:45:00"
                        id="example-datetime-local-input"
                      />
                    </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card outline color="grey" className="border">
                        <CardBody>
                        <h5 className="text-primary">
                  Customer Details
                  </h5>
                <Form>
                <Row>
                      <Col md={6}>
                        <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">First Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-1">
                          <Label htmlFor="formrow-password-Input">Last Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-lastname-Input"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                      <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                          />
                          </div>
                      </Col>
                      <Col md={6}>
                      <div className="mb-1">

                          <Label htmlFor="formrow-password-Input">Mobile</Label>
                          <Input
                            type="mobile"
                            className="form-control"
                            id="formrow-password-Input"
                          />
                          </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="example-date-input"
                    >
                      Date of Birth
                    </label>
                      <input
                        className="form-control"
                        type="date"
                        defaultValue="2019-08-19"
                        id="example-date-input"
                      />
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="sex-input"
                    >
                      Gender
                    </label>
                      <select className="form-control">
                        <option>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </select>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="ethnicity-input"
                    >
                      Ethnicity
                    </label>
                      <select className="form-control">
                        <option>Select</option>
                        <option>Any Other</option>
                        <option>Bangladeshi</option>
                        <option>Black - African</option>
                        <option>Black - Carribean</option>
                        <option>Black - Other</option>
                        <option>Chinese</option>
                        <option>Indian</option>
                        <option>Pakistani</option>
                        <option>White</option>
                        <option>White and Asian</option>
                        <option>White and Black</option>




                      </select>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="ethnicity-input"
                    >
                      Vaccination
                    </label>
                      <select className="form-control">
                        <option>Select</option>
                        <option>No</option>
                        <option>1st Dose</option>
                        <option>1st and 2nd Dose</option>

                      </select>
                    </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md={6}>
                      <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">Passport/ID Number</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                          />
                          </div>
                      </Col>
                      <Col md={6}>
                      <div className="mb-1">

                          <Label htmlFor="formrow-password-Input">Passport/ID Issuing Country</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-password-Input"
                          />
                          </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3">Home Address</h5>
                    <Row>
                      <Col md={12}>
                      <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">Address Line</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                          />
                          </div>
                      </Col>
                     
                    </Row>
                   

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">City</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputCity"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">State</Label>
                          <select
                            id="formrow-InputState"
                            className="form-control"
                          >
                            <option defaultValue>Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">Country</Label>
                          <select
                            id="formrow-InputState"
                            className="form-control"
                          >
                            <option defaultValue>Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputZip">Zip</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputZip"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="formrow-customCheck"
                        />
                        <Label
                          className="form-check-Label"
                          htmlFor="formrow-customCheck"
                        >
                          I confirm that the above details are correct (this information will on the travel certificate of your customer)


                        </Label>
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Book the test
                      </button>
                    </div>
                  </Form>
                  </CardBody>
                  </Card>
                </Container>
              </div>
            </SimpleBar>

          </ReactDrawer>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables
