import React, { useState } from "react"
import { Link } from "react-router-dom"
import ReactDrawer from 'react-drawer';
import Select from "react-select"

import {
  Card,
  CardBody,
  CardHeader,
  Badge,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle, Button,
  Form,Input,Label
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import classnames from "classnames"
import SimpleBar from "simplebar-react";


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

import Overview from "./../Overview"


const DatatableTables = () => {

  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [selectedGroup, setselectedGroup] = useState(null)

  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleTopDrawer = () => {
    setPosition('top');
    setOpen(!open)
  }
  const toggleBottomDrawer = () => {
    setPosition('bottom');
    setOpen(!open)
  }
  const toggleLeftDrawer = () => {
    setPosition('left');
    setOpen(!open)
  }
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }
  const onDrawerClose = () => {
    setOpen(false);
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "North East",
      options: [
        { label: "Newcastle Test Centre - Unit 1 Killingworth Road Newcastle NE3 1SY", value: "Newcastle Test Centre - Unit 1 Killingworth Road Newcastle NE3 1SY" },
        { label: "Stockton-on-Tees Test Centre - 148 High Street Stockton on Tees TS18 1PL", value: "Stockton-on-Tees Test Centre - 148 High Street Stockton on Tees TS18 1PL" }
      ]
    },
    {
      label: "North West",
      options: [
        { label: "Liverpool Test Centre - 57 Rodney Street Liverpool L1 9AT", value: "Liverpool Test Centre - 57 Rodney Street Liverpool L1 9AT" },

        { label: "Liverpool Rose Lane Test Centre - Rose Lane Mossley Hill Liverpool L18 8ES", value: "Liverpool Rose Lane Test Centre - Rose Lane Mossley Hill Liverpool L18 8ES" },
        { label: "Manchester Test Centre - Unit 3, Empress Business Centre Chester Rd M16 9EA", value: "Manchester Test Centre - Unit 3, Empress Business Centre Chester Rd M16 9EA" }
      ]
    }
  ]


  const columns = [ {
    dataField: 'region',
    text: 'Region',
    sort: true
  }, {
    dataField: 'provider',
    text: 'Provider',
    sort: true
  }, {
    dataField: 'traveltype',
    text: 'Type',
    sort: true
  }, {
    dataField: 'test',
    text: 'Test',
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12 badge-soft-dark"}
       
        pill
      >
        {row.test}
      </Badge>
    ),
  }, {
    dataField: 'result',
    text: 'Result',
    sort: true
  }, {
    dataField: 'servicetype',
    text: 'Service',
    sort: true
  }, {
    dataField: 'testtype',
    text: 'Where',
    sort: true
  }, {
    dataField: 'price',
    text: 'Price',
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <>
        
       {row.currency} {row.price}
      </>
    ),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "Book",
    sort: true,
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        toggleRightDrawer()
      }
    },
    // eslint-disable-next-line react/display-name
    formatter: () => (
      <Button
        type="button"
        color="primary"
        className="btn-sm"
       
      >
        Book
      </Button>
    )
  },

];

  // Table Data
  const productData = [
    {
      "id": 1,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Pre-Departure",
      "test": "Fit to Fly | RT-PCR Test",
      "testinfo": "An RT-PCR test for SARS-CoV-2 (COVID19) with a certificate you will need for travel.",
      "result": "Results by midnight",
      "servicetype": "Regular",
      "testtype": "In-Clinic",
      "price": 69,
      "currency": "£"
    },
    {
      "id": 2,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Pre-Departure",
      "test": "Fit to Fly | RT-PCR Test",
      "testinfo": "An RT-PCR test for SARS-CoV-2 (COVID19) with a certificate you will need for travel.",
      "result": "Results by midnight",
      "servicetype": "Emergency Same Day Appointment",
      "testtype": "In-Clinic",
      "price": 149,
      "currency": "£"
    },
    {
      "id": 3,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Pre-Departure",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "testinfo": "Checks for viral protein can be used for entry into some countries (please check prior to booking this test).",
      "result": "Results within 2 hours",
      "servicetype": "Regular",
      "testtype": "In-Clinic",
      "price": 29,
      "currency": "£"
    },
    {
      "id": 4,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Pre-Departure",
      "test": "Fit to Fly | Lateral Flow Antigen Test",
      "testinfo": "Checks for viral protein can be used for entry into some countries (please check prior to booking this test).",
      "result": "Results within 2 hours",
      "servicetype": "Emergency Same Day Appointment",
      "testtype": "In-Clinic",
      "price": 39,
      "currency": "£"
    },
    {
      "id": 5,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "testinfo": "The government has announced that from 24 October 2021 fully vaccinated international passengers arriving in England from countries not on the red list can take a Day 2 lateral flow test instead of the PCR version.",
      "result": "Results within 2 hours",
      "servicetype": "Regular",
      "testtype": "In-Clinic",
      "price": 29,
      "currency": "£"
    },
    {
      "id": 6,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Fully Vaccinated Day 2 Antigen Test",
      "testinfo": "The government has announced that from 24 October 2021 fully vaccinated international passengers arriving in England from countries not on the red list can take a Day 2 lateral flow test instead of the PCR version.",
      "result": "-",
      "servicetype": "Regular",
      "testtype": "Postal Self Service",
      "price": 29,
      "currency": "£"
    },
    {
      "id": 7,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Day 5 Test to Release",
      "testinfo": "Reduce your quarantine by 5 days.\n\nInternational arrivals to England who have been in a red-list country in the 10 days prior to their arrival cannot opt-in to Test to Release.",
      "result": "Results by midnight",
      "servicetype": "Regular",
      "testtype": "In-Clinic",
      "price": 69,
      "currency": "£"
    },
    {
      "id": 8,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Day 5 Test to Release",
      "testinfo": "Reduce your quarantine by 5 days.\n\nInternational arrivals to England who have been in a red-list country in the 10 days prior to their arrival cannot opt-in to Test to Release.",
      "result": "Results by midnight",
      "servicetype": "Emergency Same Day Appointment",
      "testtype": "In-Clinic",
      "price": 149,
      "currency": "£"
    },
    {
      "id": 9,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Non-Vaccinated Day 2 PCR",
      "testinfo": "RETURNING FROM A NON-RED LIST COUNTRY: \n\nDOUBLE VACCINATED:\n\n· If you are double vaccinated, you do not require a Pre-Arrival test when returning to the UK.  \n\n· Book and pay for a Day 2 COVID-19 test – to be taken after arrival in England.\n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\nUNVACCINATED:\n\n· If you are not vaccinated, it is mandatory to arrange a Pre-Arrival test within 72 hours before travel to the UK. \n\n· Quarantine at home or in the place you are staying for 10 days.\n\n· Day 2 & 8 tests are required for when you return to the UK – these must be booked before departure and boarding on travel to the UK. \n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\n \n\nDay 5 Test to Release Scheme for Unvaccinated Individuals: \n\nThe Government “test-to-release” scheme is designed to release travellers from post-travel quarantine safely and speedily, 5 days after arrival into England. You will be able to reduce your self-isolation period from 10 days to just 5 days, provided that you test negative. \n\n \n\nRETURNING FROM A RED LIST COUNTRY: \n\nApplies to all:  \n\n· It is mandatory to arrange a Pre-arrival test within 72 hours before arriving in the UK. \n\n· Day 2 & 8 tests must be booked before arrival in the UK to then be completed after return. \n\n· 10-Day hotel quarantine is mandatory on return; this will be at a cost of £2,285.  \n\n· You are required to quarantine for 10 days. \n\n \n\nIf you are in England for less than 10 days, you need to quarantine for the time you are here. You need to book day 2 and day 8 travel tests. You only need to take the tests if you are still in England.",
      "result": "Results within 24 hours",
      "servicetype": "",
      "testtype": "In-Clinic",
      "price": 69,
      "currency": "£"
    },
    {
      "id": 10,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Non-Vaccinated Day 2 PCR",
      "testinfo": "RETURNING FROM A NON-RED LIST COUNTRY: \n\nDOUBLE VACCINATED:\n\n· If you are double vaccinated, you do not require a Pre-Arrival test when returning to the UK.  \n\n· Book and pay for a Day 2 COVID-19 test – to be taken after arrival in England.\n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\nUNVACCINATED:\n\n· If you are not vaccinated, it is mandatory to arrange a Pre-Arrival test within 72 hours before travel to the UK. \n\n· Quarantine at home or in the place you are staying for 10 days.\n\n· Day 2 & 8 tests are required for when you return to the UK – these must be booked before departure and boarding on travel to the UK. \n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\n \n\nDay 5 Test to Release Scheme for Unvaccinated Individuals: \n\nThe Government “test-to-release” scheme is designed to release travellers from post-travel quarantine safely and speedily, 5 days after arrival into England. You will be able to reduce your self-isolation period from 10 days to just 5 days, provided that you test negative. \n\n \n\nRETURNING FROM A RED LIST COUNTRY: \n\nApplies to all:  \n\n· It is mandatory to arrange a Pre-arrival test within 72 hours before arriving in the UK. \n\n· Day 2 & 8 tests must be booked before arrival in the UK to then be completed after return. \n\n· 10-Day hotel quarantine is mandatory on return; this will be at a cost of £2,285.  \n\n· You are required to quarantine for 10 days. \n\n \n\nIf you are in England for less than 10 days, you need to quarantine for the time you are here. You need to book day 2 and day 8 travel tests. You only need to take the tests if you are still in England.",
      "result": "",
      "servicetype": "",
      "testtype": "Postal Self Service",
      "price": 69,
      "currency": "£"
    },
    {
      "id": 11,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "testinfo": "RETURNING FROM A NON-RED LIST COUNTRY: \n\nDOUBLE VACCINATED:\n\n· If you are double vaccinated, you do not require a Pre-Arrival test when returning to the UK.  \n\n· Book and pay for a Day 2 COVID-19 test – to be taken after arrival in England.\n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\nUNVACCINATED:\n\n· If you are not vaccinated, it is mandatory to arrange a Pre-Arrival test within 72 hours before travel to the UK. \n\n· Quarantine at home or in the place you are staying for 10 days.\n\n· Day 2 & 8 tests are required for when you return to the UK – these must be booked before departure and boarding on travel to the UK. \n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\n \n\nDay 5 Test to Release Scheme for Unvaccinated Individuals: \n\nThe Government “test-to-release” scheme is designed to release travellers from post-travel quarantine safely and speedily, 5 days after arrival into England. You will be able to reduce your self-isolation period from 10 days to just 5 days, provided that you test negative. \n\n \n\nRETURNING FROM A RED LIST COUNTRY: \n\nApplies to all:  \n\n· It is mandatory to arrange a Pre-arrival test within 72 hours before arriving in the UK. \n\n· Day 2 & 8 tests must be booked before arrival in the UK to then be completed after return. \n\n· 10-Day hotel quarantine is mandatory on return; this will be at a cost of £2,285.  \n\n· You are required to quarantine for 10 days. \n\n \n\nIf you are in England for less than 10 days, you need to quarantine for the time you are here. You need to book day 2 and day 8 travel tests. You only need to take the tests if you are still in England.",
      "result": "Results within 24 hours",
      "servicetype": "Results within 24 hours",
      "testtype": "In-Clinic",
      "price": 119,
      "currency": "£"
    },
    {
      "id": 12,
      "region": "United Kingdom",
      "provider": "DAM Health",
      "traveltype": "Arrival",
      "test": "Non-Vaccinated Day 2 & 8 PCR",
      "testinfo": "RETURNING FROM A NON-RED LIST COUNTRY: \n\nDOUBLE VACCINATED:\n\n· If you are double vaccinated, you do not require a Pre-Arrival test when returning to the UK.  \n\n· Book and pay for a Day 2 COVID-19 test – to be taken after arrival in England.\n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\nUNVACCINATED:\n\n· If you are not vaccinated, it is mandatory to arrange a Pre-Arrival test within 72 hours before travel to the UK. \n\n· Quarantine at home or in the place you are staying for 10 days.\n\n· Day 2 & 8 tests are required for when you return to the UK – these must be booked before departure and boarding on travel to the UK. \n\n· Complete a passenger locator form – to be completed in the 48 hours before you arrive in England.\n\n \n\nDay 5 Test to Release Scheme for Unvaccinated Individuals: \n\nThe Government “test-to-release” scheme is designed to release travellers from post-travel quarantine safely and speedily, 5 days after arrival into England. You will be able to reduce your self-isolation period from 10 days to just 5 days, provided that you test negative. \n\n \n\nRETURNING FROM A RED LIST COUNTRY: \n\nApplies to all:  \n\n· It is mandatory to arrange a Pre-arrival test within 72 hours before arriving in the UK. \n\n· Day 2 & 8 tests must be booked before arrival in the UK to then be completed after return. \n\n· 10-Day hotel quarantine is mandatory on return; this will be at a cost of £2,285.  \n\n· You are required to quarantine for 10 days. \n\n \n\nIf you are in England for less than 10 days, you need to quarantine for the time you are here. You need to book day 2 and day 8 travel tests. You only need to take the tests if you are still in England.",
      "result": "",
      "servicetype": "",
      "testtype": "Postal Self Service",
      "price": 119,
      "currency": "£"
    }
  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Providers" />



          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
              <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-flex flex-wrap">
                                <UncontrolledDropdown
                                  className="ms-auto"
                                >
                                  <DropdownToggle
                                    className="btn btn-light btn-sm"
                                    color="#eff2f7"
                                    type="button"
                                  >
                                    <i className="bx bx-filter align-middle me-1"></i>Region
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                       
                                    <Link className="dropdown-item" to="#">
                                     United Kingdom
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      United States
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      United Arab Emirates
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Denmark
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Sweden
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Netherlands
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      France
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Portugal
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                     Spain
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Switzerland
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Italy
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Czech
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Austria
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Slovakia
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Mexico
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Peru
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Malaysia
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown
                                  className="ms-2"
                                >
                                  <DropdownToggle
                                    className="btn btn-light btn-sm"
                                    color="#eff2f7"
                                    type="button"
                                  >
                                    <i className="bx bx-filter align-middle me-1"></i>Provider
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <Link className="dropdown-item" to="#">
                                      DAM Health
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Collison
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      Corona Test Center
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                    Pantai Premier Pathology
                                    </Link>
                                    

                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown
                                  className="ms-2"
                                >
                                  <DropdownToggle
                                    className="btn btn-light btn-sm"
                                    color="#eff2f7"
                                    type="button"
                                  >
                                    <i className="bx bx-filter align-middle me-1"></i> Test Type
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <Link className="dropdown-item" to="#">
                                    Fit to Fly | RT-PCR Test
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                    Fit to Fly | Lateral Flow Antigen Test
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                    Fully Vaccinated Day 2 Antigen Test
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                    Day 5 Test to Release
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                    Non-Vaccinated Day 2 PCR
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                    Non-Vaccinated Day 2 & 8 PCR
                                    </Link>
                                    
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown
                                  className="ms-2"
                                >
                                  <DropdownToggle
                                    className="btn btn-light btn-sm"
                                    color="#eff2f7"
                                    type="button"
                                  >
                                    <i className="bx bx-filter align-middle me-1"></i> Where
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <Link className="dropdown-item" to="#">
                                      In-clinic
                                    </Link>
                                    <Link className="dropdown-item" to="#">
                                      At Home
                                    </Link>
                                   
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <hr />
                              </div>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive ">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                   
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>

</CardBody>
              </Card>
            </Col>
          </Row>
          <ReactDrawer
            open={open}
            position={position}
            onClose={onDrawerClose}
          >
            <SimpleBar style={{ height: "900px" }}>
              <div data-simplebar className="h-100">

                <Container className="mt-3">
                <Card outline color="grey" className="border">
               
                <CardBody>
                <h5 className="text-primary">
                  Fit to Fly | RT-PCR Test - £69
                  </h5>
                  <CardTitle className="mt-0">DAM Health</CardTitle>
               
                  <Row>
                  <Col md={6}>
                  <div className="mb-1">
                    <Label htmlFor="formrow-email-Input">Choose the test center </Label>
                      <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                   <Button className="btn-sm mt-2">View on Map</Button>
                    </div>
                    </Col>
                    <Col md={6}>
                    <div className="mb-1">

                    <label
                      htmlFor="example-datetime-local-input"
                    >
                      Choose Date and Time
                    </label>
            
                      <input
                        className="form-control"
                        type="datetime-local"
                        defaultValue="2021-11-01T13:45:00"
                        id="example-datetime-local-input"
                      />
                    </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card outline color="grey" className="border">
                        <CardBody>
                        <h5 className="text-primary">
                  Customer Details
                  </h5>
                <Form>
                <Row>
                      <Col md={6}>
                        <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">First Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-1">
                          <Label htmlFor="formrow-password-Input">Last Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-lastname-Input"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                      <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                          />
                          </div>
                      </Col>
                      <Col md={6}>
                      <div className="mb-1">

                          <Label htmlFor="formrow-password-Input">Mobile</Label>
                          <Input
                            type="mobile"
                            className="form-control"
                            id="formrow-password-Input"
                          />
                          </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="example-date-input"
                    >
                      Date of Birth
                    </label>
                      <input
                        className="form-control"
                        type="date"
                        defaultValue="2019-08-19"
                        id="example-date-input"
                      />
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="sex-input"
                    >
                      Gender
                    </label>
                      <select className="form-control">
                        <option>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </select>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="ethnicity-input"
                    >
                      Ethnicity
                    </label>
                      <select className="form-control">
                        <option>Select</option>
                        <option>Any Other</option>
                        <option>Bangladeshi</option>
                        <option>Black - African</option>
                        <option>Black - Carribean</option>
                        <option>Black - Other</option>
                        <option>Chinese</option>
                        <option>Indian</option>
                        <option>Pakistani</option>
                        <option>White</option>
                        <option>White and Asian</option>
                        <option>White and Black</option>




                      </select>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="mb-1">

                    <label
                      htmlFor="ethnicity-input"
                    >
                      Vaccination
                    </label>
                      <select className="form-control">
                        <option>Select</option>
                        <option>No</option>
                        <option>1st Dose</option>
                        <option>1st and 2nd Dose</option>

                      </select>
                    </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md={6}>
                      <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">Passport/ID Number</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                          />
                          </div>
                      </Col>
                      <Col md={6}>
                      <div className="mb-1">

                          <Label htmlFor="formrow-password-Input">Passport/ID Issuing Country</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-password-Input"
                          />
                          </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3">Home Address</h5>
                    <Row>
                      <Col md={12}>
                      <div className="mb-1">
                          <Label htmlFor="formrow-email-Input">Address Line</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                          />
                          </div>
                      </Col>
                     
                    </Row>
                   

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">City</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputCity"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">State</Label>
                          <select
                            id="formrow-InputState"
                            className="form-control"
                          >
                            <option defaultValue>Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">Country</Label>
                          <select
                            id="formrow-InputState"
                            className="form-control"
                          >
                            <option defaultValue>Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputZip">Zip</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputZip"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="formrow-customCheck"
                        />
                        <Label
                          className="form-check-Label"
                          htmlFor="formrow-customCheck"
                        >
                          I confirm that the above details are correct (this information will on the travel certificate of your customer)


                        </Label>
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Book the test
                      </button>
                    </div>
                  </Form>
                  </CardBody>
                  </Card>
                </Container>
              </div>
            </SimpleBar>

          </ReactDrawer>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables
