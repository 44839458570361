import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

//SimpleBar
import SimpleBar from "simplebar-react"
// import images
import user1 from "../../assets/images/users/avatar-2.jpg"

const Comments = props => {
  return (
    <React.Fragment>
      <Col xl={12} lg={12}>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-2">
                <h5 className="card-title mb-3">Latest Updates on Travel Restrictions</h5>
              </div>
              <UncontrolledDropdown className="ms-auto">
                <DropdownToggle
                  className="text-muted font-size-16"
                  color="white"
                  type="button"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu direction="right">
                  <Link className="dropdown-item" to="#">
                    Action
                  </Link>
                  <Link className="dropdown-item" to="#">
                    Another action
                  </Link>
                  <Link className="dropdown-item" to="#">
                    Something else
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="#">
                    Separated link
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <SimpleBar style={{ maxHeight: "600px" }}>
              <div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item py-3">
                    <div className="media">
                    
                      <div className="media-body">
                        <h5 className="font-size-14 mb-2">
                        Travelers departing from Canadian airports require negative COVID-19 test results
                          <small className="text-muted float-end">
                          Canada
                          </small>
                        </h5>
                        <p className="text-muted">
                        Travelers departing from Canadian airports, rail, and passengers vessels on voyages of 24 hours or more require negative molecular COVID-19 test results in order to board. This requirement applies to international and domestic departures.
                        </p>
                        <div>
                        <small className="text-muted">
                          Effective today, October 30, 2021
                          </small>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item py-3">
                    <div className="media">
                    
                      <div className="media-body">
                        <h5 className="font-size-14 mb-2">
                        Travel is allowed with restrictions                       
                           <small className="text-muted float-end">
                          United Kingdom
                          </small>
                        </h5>
                        <p className="text-muted">
                        Travelers are allowed to enter the United Kingdom. Please check the documentation, quarantine, and/or testing requirements for your specific destination before traveling. 
                        </p>
                        <div>
                        <small className="text-muted">
                          Effective today, October 30, 2021
                          </small>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item py-3">
                    <div className="media">
                    
                      <div className="media-body">
                        <h5 className="font-size-14 mb-2">
                        Transit is allowed with restrictions for unvaccinated travelers                      
                           <small className="text-muted float-end">
                          United Kingdom
                          </small>
                        </h5>
                        <p className="text-muted">
                        Unvaccinated travelers are allowed to transit through London Heathrow Airport following the listed procedures. 

Travelers should check the travel restrictions of their destination country to make sure they can transit through the UK.

Most transit travelers are required to:
-Stay at the airport. Travelers that leave the airport may be required to quarantine;
-Provide proof of a valid negative COVID-19 test result from a test taken any time in the 3 days before departure of the last direct flight to the United Kingdom. Tests can be a nucleic acid (PCR), LAMP, or antigen (such as LFD) test and should meet at least 97% specificity and 80% sensitivity. Negative test results can be in English, French, or Spanish;
-Fill out the passenger locator form before arrival.

Travelers not leaving the international departure area are not required to book a COVID-19 testing package or undergo quarantine.

Please see the source for more details. 
                        </p>
                        <div>
                        <small className="text-muted">
                          Effective today, October 30, 2021
                          </small>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item py-3">
                    <div className="media">
                    
                      <div className="media-body">
                        <h5 className="font-size-14 mb-2">
                        Proof of negative COVID-19 test results required for unvaccinated travelers                     
                           <small className="text-muted float-end">
                          United Kingdom
                          </small>
                        </h5>
                        <p className="text-muted">
                        Unvaccinated travelers from selected countries must have a valid negative COVID-19 nucleic acid (PCR), LAMP, or antigen (such as LFD) test result in English, French, or Spanish. The test must be taken any time in the 3 days before departure of the last direct flight to the United Kingdom. Travelers arriving directly to England on Friday could take a test any time on Tuesday, Wednesday, Thursday, or Friday. The test result must be available for boarding. The test should meet at least 97% specificity and 80% sensitivity. 

This requirement applies to all travelers including United Kingdom citizens, residents, and all transit passengers. 
                        </p>
                        <div>
                        <small className="text-muted">
                          Effective today, October 30, 2021
                          </small>
                        </div>
                      </div>
                    </div>
                  </li>

                 
                </ul>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Comments
