import React from "react"
import { Col, Card, CardBody, Media } from "reactstrap"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Images
import img1 from "../../assets/images/companies/img-1.png"
import img2 from "../../assets/images/companies/img-2.png"
import img3 from "../../assets/images/companies/img-3.png"

const Notifications = () => {
  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Agent Chatter</h4>
            <SimpleBar style={{ maxHeight: "565px" }}>
              <ul className="list-group">
                <li className="list-group-item border-0">
                  <Media>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={img1} alt="" height="18" />
                      </span>
                    </div>
                    <Media body>
                      <h5 className="font-size-14">Canada</h5>
                      <p className="text-muted">
                        Looks like Canada has announced some new restrictions
                      </p>

                      <div className="float-end">
                        <p className="text-muted mb-0">
                          <i className="mdi mdi-account me-1" /> Joseph
                        </p>
                      </div>
                      <p className="text-muted mb-0">1 Nov, 2021</p>
                    </Media>
                  </Media>
                </li>
                <li className="list-group-item border-0">
                  <Media>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={img2} alt="" height="18" />
                      </span>
                    </div>
                    <Media body>
                      <h5 className="font-size-14">
                        USA
                      </h5>
                      <p className="text-muted">
                        Can people from India travel to USA ?
                      </p>

                      <div className="float-end">
                        <p className="text-muted mb-0">
                          <i className="mdi mdi-account me-1" /> Jerry
                        </p>
                      </div>
                      <p className="text-muted mb-0">1 Nov, 2021</p>
                    </Media>
                  </Media>
                </li>
                <li className="list-group-item border-0">
                  <Media>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={img3} alt="" height="18" />
                      </span>
                    </div>
                    <Media body>
                      <h5 className="font-size-14">
                        Dubai
                      </h5>
                      <p className="text-muted">
                        A customer of mine is asking if RT-PCR is required for Dubai? Does anyone know?
                      </p>

                      <div className="float-end">
                        <p className="text-muted mb-0">
                          <i className="mdi mdi-account me-1" /> Calvin
                        </p>
                      </div>
                      <p className="text-muted mb-0">1 Nov, 2021</p>
                    </Media>
                  </Media>
                </li>
                <li className="list-group-item border-0">
                  <Media>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={img1} alt="" height="18" />
                      </span>
                    </div>
                    <Media body>
                      <h5 className="font-size-14">Ghana</h5>
                      <p className="text-muted">
                        Can people from Ghana go to London? What tests are required?
                      </p>

                      <div className="float-end">
                        <p className="text-muted mb-0">
                          <i className="mdi mdi-account me-1" /> Joseph
                        </p>
                      </div>
                      <p className="text-muted mb-0">1 Nov, 2021</p>
                    </Media>
                  </Media>
                </li>
                <li className="list-group-item border-0">
                  <Media>
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={img1} alt="" height="18" />
                      </span>
                    </div>
                    <Media body>
                      <h5 className="font-size-14">Spain</h5>
                      <p className="text-muted">
                       Which is the best testing center in Spain?
                      </p>

                      <div className="float-end">
                        <p className="text-muted mb-0">
                          <i className="mdi mdi-account me-1" /> Joseph
                        </p>
                      </div>
                      <p className="text-muted mb-0">1 Nov, 2021</p>
                    </Media>
                  </Media>
                </li>
              </ul>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Notifications
